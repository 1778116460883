'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { useParams } from 'next/navigation';

import { Button } from '@zealy/design-system';

import { OpenSupportButton } from '#components/open-support-button';

export default function NotFound() {
  const t = useTranslations('404');
  const { subdomain } = useParams<{ subdomain: string }>();

  return (
    <main className="px-150 md:px-300 h-full mx-auto max-w-[1400px]">
      <div className="h-4/5 grid content-center max-w-[546px]">
        <h4 className="section-heading text-brand-primary mb-200">{t('subtitle')}</h4>
        <h1 className="heading-1 text-primary mb-300">{t('title')}</h1>
        <p className="text-secondary body-lg">{t('description')}</p>
        <div className="flex items-center mt-500 gap-200">
          <Button as={Link} href={subdomain ? `/cw/${subdomain}` : '/'}>
            {t('return-home')}
          </Button>
          <Button as={Link} href="/">
            Test
          </Button>
          <OpenSupportButton title={t('contact-support')} />
        </div>
      </div>
    </main>
  );
}
